/**
 * @Description 
 * @FileName fasatErrorPopOut.scss
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 23 March, 2021 16:21:32
 * @IssueID - Issue IDs
*/

@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

$tooltip-margin-negetive: -30px;
$tooltip-arrow-size-negetive: -6px;
$tooltip-arrow-size-negetive-double: -12px;

/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.fasat-error-popout-wrapper {
  display: block;
  position: relative;
  .eleWithNoWrapLabel & {
    .fasatTextInput {
      div[class^='styledComponents__LabelWrapper'] {
        white-space: nowrap;
      }
    }
  }
}

/*summary*/
.summary{
  color: $red_error;
  font-family: $global-font;
  // text-decoration: underline;
  span{
    cursor: pointer;
    text-decoration: underline;
    float: right;
  }
}

#addEditDefaultCompCal {
  .summary{
    font-family: $global-font;
  }
}

/* Absolute positioning */
.fasat-error-popout {
  flex: 1;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  /* white-space: nowrap;*/
  width: 100%;
  padding: 10px;
}

/* CSS border triangles */
.fasat-error-popout::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: $tooltip-arrow-size-negetive;
}

/* Absolute positioning */
.fasat-error-popout.top {
  top: $tooltip-margin-negetive;
}
/* CSS border triangles */
.fasat-error-popout.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.fasat-error-popout.right {
  left: calc(100% + var(--tooltip-margin));    
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.fasat-error-popout.right::before {
  left: $tooltip-arrow-size-negetive;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.fasat-error-popout.bottom {
  margin-top: 5px;
  text-overflow: ellipsis;
  /*bottom: calc(var(--tooltip-margin) * -1);*/
}
/* CSS border triangles */
.fasat-error-popout.bottom::before {
  bottom: 100%;
  left: 88%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.fasat-error-popout.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.fasat-error-popout.left::before {
  left: auto;
  right: $tooltip-arrow-size-negetive-double;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.hideError {
  position: absolute;
  top: 5px;
  right: 5px;
}

/* Server Error */
.serverError {
  button svg {
    width: 15px;
    height: 15px;
  }
}

.adjCheckBox {
  float: left;
margin-right: 35px
}

#viewBankingDetails {
  margin-top : 44px;
}