@import '../../../../assets/theme/colors.scss';
@import '../../../../assets/theme/globalFonts.scss';

.chipContainer {
  font-family: $global-font-demibold;
  font-size: 14px;
  line-height: 1.43;
  display: inline-block;
  background-color: $light_1_grey;
  color: $light_1_dark_navy;
  margin: 0 8px 8px 0;
  padding: 8px;
  &.defaultChip {
    border-left: 4px solid $blue;  
    padding: 8px 12px 8px 8px;
  }
  img {
    cursor: pointer;
    vertical-align: -4px;
  }
}