@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatGridBar {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 8px 0;  
  .pageSizeSection {
    font-family: $global-font;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $light_1_dark_navy;
    flex: 1;
    .pageSizeDropdown {
      display: inline-block;      
      margin: 0 12px;
      width: 100px;
      height: 48px;
      div[id$='-value'] {
        padding-right: 64px;
      }
      .fasatDropdown > div > label {
        display: none;
      }
    }
  }
  .actionSection {
    flex: 1;
    text-align: right;
    display: contents;
    button {
      margin-left: 24px;
      height: 20px;
      margin-top: 13px;
      &:disabled {
        color: $light_3_dark_navy;
        pointer-events: none;
      }
    }
    .privateCheckbox {
      margin-right: 36px;
      .fasatCheckbox {
        margin-top: 6px;
      }
    }
    .checkBoxInGridBar {
      .fasatCheckbox {
        margin-top: 8px;
      }
    }
    .chipContainer.defaultChip {
      margin: 4px 16px 4px 4px;
      height: max-content;
    }
  }
  .actionsDropdown {
    display: none;
  }
  .ffBalanceProdTabLand & {
    padding-top: 32px !important;
  }
}

@media screen and (max-width: 599px) {
  .fasatGridBar {
    display: block;
    .actionSection {
      display: flex;
      justify-content: end;
      button {
        margin-left: 24px;
        margin-right: 0;

      }
    }
  }
  .fasatGridBar .actionsDropdown {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    button {
      display: contents;
    }
  }
  .fasatPage .fasatGridBar:nth-child(2) {
    padding-top: 75px;
  }
}