@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';
.detailSection {
  font-family: $global-font;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $light_1_dark_navy;
  margin-bottom: 32px;
  div:last-child {
    padding-bottom: 0;
  }
}