/**
 * @Description Fonts related stylings
 * @FileName fonts.scss
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 08 February, 2021 07:43:06
 * @IssueID 325
 */
.f-bold{
  font-weight: 700;
}
.f-demi-bold{
  font-weight: 600;
}
