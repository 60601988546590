@import '../../../../assets/theme/colors.scss';
.resizer {
  display: inline-block;
  background: none;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;

  &:hover {
    background: #00a758;
  }

  &.isResizing {
    background: #00a758;
  }
}

.pdCheck {
  img {
    vertical-align: middle;
  }
}

.resizerCell {
  &.isResizing {
    border-right: 2px solid #00a758;
  }
  button {
    width: 20px;
    height: 20px;
    margin-right: 24px;
    svg {
      vertical-align: middle;
    }
    span:nth-child(2) {
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:disabled {
      pointer-events: none;
      svg {
        color: $dark_3_light_grey;
      }
    }
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  span[role="tooltip"] {
    button {
      margin-right: 0;
    }
  }
}
tr {
  td.withNoRow {
    position: inherit !important;
    text-align: left !important;
  }
}

tr.preSelectedRow {
  td {
    background: #fbe9c6;
  }
  @-moz-document url-prefix() {
    td:last-child {
      margin-top: -30px !important;
    }
  }
}

th{
  .gridCheckbox {
    position: absolute;
  }
}

tr.highlightedRow, tr.selectedRow {
  background: $light_grey !important;
  td {
    font-weight: normal;
    &:last-child {
      background: $light_grey !important;
    }
  }
}

.checkBoxGrid {
  tr{
    th:first-child{
      position: sticky !important;
      padding-top: 8px;
      padding-bottom: 8px;
      border-right: 2px solid $light_grey;
      background-color: $white;
      background-color: white;
      left: 0;
      z-index: 1;
    }
    td:first-child{
      position: sticky !important;
      padding-top: 7px;
      padding-bottom: 6px;
      border-right: 2px solid $light_grey;
      background-color: white;
      left: 0;
      z-index: 0;
    }
    th:nth-child(2), td:nth-child(2){
      padding-left: 10px;
    }
    &.selectedRow, &.highlightedRow {
      background-color: $light_grey;
      td:first-child {
        background-color: $light_grey;
      }
    }
    td.withNoRow {
      position: inherit !important;
      border-bottom: 2px solid $light_grey;
      text-align: left !important;
    }
  }
}

@-moz-document url-prefix() {
  .checkBoxGrid tr td:first-child {
    padding-top: 7px;
  }
  .checkBoxGrid tr td .gridCheckboxDiv {
    margin-top: -30px;
    background-color: $white;
    margin-left: -16px;
    padding-left: 16px;
  }
  .checkBoxGrid tr.selectedRow td .gridCheckboxDiv {
    background-color: $light_grey;
  }
}

span.p-column-title {
  display: none;
}

.sysParam [data-testid="CustomGridComponent"] {
  overflow-x: hidden;
}
.dp-statements-xmllife-tab{
  [data-testid="CustomGridComponent"] {
    overflow-x: hidden;
    td.withNoRow{
      box-shadow: none;
    }
  }

}

.customFilterListModal {  
  [data-testid="CustomGridComponent"] {
    th:last-child {
      padding-top: 90px;
    }
  }
}

.select-letters .selectLetterContainer{
  [data-testid="CustomGridComponent"] {
    // overflow-x: hidden;
    td.withNoRow{
      box-shadow: none;
    }
  }

}

table {
  width: 100%;
  margin-bottom: 2px;
}

@media screen and (min-width: 1281px) {
  div[data-testid="CustomGridComponent"] {
    width: 92%;
  }
}