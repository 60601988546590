@import '../../assets/theme/colors.scss';

.fasatDatePicker {
  input {
    height: 44px;
  }
  span[class^="styledComponents__OptionalText"]{
    display: none;
  }
  div[data-testid="ifsLastRun-picker"] {
    right: 0;
  }
}
.noLabelDateField {
  .fasatDatePicker {
    div[class^="styledComponents__LabelWrapper"] {
      display: none;
    }
  }
}

.noLabelDateFieldAODA {
  .fasatDatePicker {
    div[class^="styledComponents__LabelWrapper"] {
      border: 0;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;     
    }
  }
}

.customDatePickerError div[data-testid="input-wrapper"]{
    border-style: solid!important;
    border-color: $dark_3_light_grey!important;
    border-width: 2px!important;
}

.customDatePickerError, .withError div[data-testid="input-wrapper"]{
  border-color: $red_error !important;

}

#rdToDtIP-picker, #rdFromDtIP-picker {
  bottom: 0;
  padding: 8px;
  width: 320px;
  td {
    padding: 0;
  }
}
