@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

h2 {
  &.fasatH2 {
    font-family: $global-font;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: $light_1_dark_navy;
    margin-bottom: 8px;
    margin-top: 0;
  }
  .statementGridSection & {
    margin: 32px 0;
  }
}


@media screen and (max-width: 1279px) {
  h2 {
    &.fasatH2 {
      font-size: 26px;
    }
  }
}