@import '../../../../../../index.scss';

.columnFilterContainer{
  display: flex;  
  // margin: 20px 0;
  font-family: $global-font-demibold;
  line-height: 1.5;
  color: $light_1_dark_navy;
  .listbox-ul {
    list-style-type: none;
    border: solid 1px $dark_3_light_grey;
    padding: 0px;
    margin: 0px;
    &:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
      .focused-ring {
        outline: -webkit-focus-ring-color auto 1px!important;
      }
    } 
  
    li {
      font-weight: normal;
      display: block;
      white-space: nowrap;
      min-height: 1.2em;
      padding: 12px;
      border: 1px solid $light_grey;
      color: $light_1_dark_navy;
      cursor: pointer;
      
      &.selected {
        background-color: $light_grey;
        font-weight: normal;
        font-family: $global-font!important;
      }

    }
  }
  .rdl-list-box{
    width: 40%;
    &:first-child {
      .rdl-control {
        max-height: 396px;
      }
    }
    .rdl-control-container {
      height: 445px;
      margin-top: 8px;
      .rdl-filter-container { 
        border: 1px solid $dark_3_light_grey;
        border-bottom: 0;
        position: relative;
        .rdl-filter {
          border: 0;
          padding: 12px;
          font-family: $global-font-light;
          font-size: 16px;
          line-height: 1.5;
          width: -webkit-fill-available;
          width: -moz-available;
          outline: none;
        }
        img {
          position: absolute;
          top: 14px;
          right: 13px;
        }
      }
      .rdl-control {
        width: 99.5%;
        overflow-y: auto;
        font-family: $global-font-light;
        font-size: 16px;
        color: $light_1_dark_navy;
        border-color: $dark_3_light_grey;
        height: inherit;
        li {
          padding: 12px;
          border-bottom: 1px solid $light_grey;
          color: $light_1_dark_navy;
          min-height: 21px;
          white-space: break-spaces;
          &:hover, &:active, &:checked, &:focus, &::selection {
            font-family: $global-font-regular;
            background-color: $light_grey;
          }
        }
      }
    }
  }
  .rdl-actions {
    text-align: center;
    margin: 8px;
    padding: 0 20px;
    position: relative;
    div {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      button {
        border: 0;
        background: none;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .columnFilterContainer {
    flex-direction: column;
    .rdl-list-box {
      width: 100%;
    }
    .rdl-actions {
      div {
        position: static;
        transform: none;
        button {
          margin: 8px;
        }
      }
      &.actionLeftRight {
        button {
          transform: rotate(90deg);
        }
      }
    }
  }
}

