.fasatButtonSelector {
  legend {
    margin-bottom: 8px;
  }
  button {
    padding: 12px 0;
  }
}
.addEditTaskTab {
  button[aria-checked="true"] {
    pointer-events: none;
  }
}