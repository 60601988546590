@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.advisorDetailsSectionExt {
  .infoCell {
    width: 308px !important;
  }
}
.masterInfoSection, .advisorDetailsSectionExt {
  margin-bottom: 20px;
  .infoCell {
    width: 240px;
    display: inline-block;
    margin: 0 20px 20px 0;
    vertical-align: top;
    label {
      font-family: $global-font-demibold;
      margin-bottom: 0;
      .dateFormatSpanPD {
        font-family: $global-font;
      }
    }
    .labelContent {
      label {        
        font-family: $global-font;
      }
    }
  }
  .compHeader &{
    padding-bottom: 12px;
    border-bottom: solid 2px $light_grey;
    margin-bottom: 40px;
  }
}
