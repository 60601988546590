
@import '../../assets/theme/globalFonts.scss';

.fasatMessageCard {
    margin-bottom: 32px;
    .ffServerError & {
        margin-top: 32px;
        margin-bottom: 0;
    }
    .relList & {
        margin-bottom: 0;
        padding-top: 32px;
    }
    .extServerError &{
        margin: 20px 0;
    }
    .tabServerError &, .tdTabError & {
        margin-top: 32px;
        .clientBankingContainer &, .clientLicenceContainer &, .clientAddressContainer &{
            margin-bottom: 12px;
        }
    }
    .beMsgCard {
        display: inline-block;
    }
    .msgcard-text{
        font-family: $global-font;    
        margin: 0px;    
        white-space: break-spaces;
    
    }
    .paymentMsgSection {
        margin-top: 20px;
    }
}
.demiboldText {
    font-family: $global-font-demibold;
}
