@import '../../../../assets/theme/colors.scss';
@import '../../../../assets/theme/globalFonts.scss';

.fasatBatchExport {
  margin-top: 32px;
  border: 1px solid #ececec;
  padding: 16px 0 0;
  width: 788px;
  .beHeader {
    display: flex;
    padding: 0 32px 16px;
    border-bottom: 1px solid #ededed;
    .headerLabel {
      flex: 1;
      h2 {
        font-family: $global-font;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        margin: 0;
      }
    }
  }
  .beSection {
    padding: 32px;
    .lastRef {
      label {
        font-size: 14px;
        text-align: right;
      }
    }
    .fasatMessageCard {
      margin-bottom: 20px;
    }
    .fasatGridBar {
      padding-top: 0;
    }
  }
}