#manageAppButton {
    width: 240px;
    margin-top: 32px;
}

#rateDetailsAllViewBtn {
    padding: 0;
    margin-top: 32px;
    margin-bottom: 32px;
}

@media screen and (max-width: 599px) {
    .extFasatBtn {
        display: flex;
        flex-direction: column-reverse;
        button {
            width: 100% !important;
            margin-bottom: 20px;
        }
    }
}