@import '../../../../../../index.scss';

.actionableTextInput, .actionableTextInputWithLabel {
  position: relative;
  .selectedContainer {
    margin-top: 8px;
    word-break: break-all;
  }
  .addLinkContainer {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    padding: 11px 24px;    
    span {     
      a, a:hover, a:active {
        font-family: $global-font-demibold;
        font-size: 14px;
        color: $light_1_dark_navy;
        cursor: pointer;
      }
    }
  }  
  .fasatTextInput {
    input {
      padding-right: 64px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.actionableTextInputWithLabel .addLinkContainer {
  top: 30px;
}
