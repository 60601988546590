@import '../../../../index.scss';

.footerBarWrapper {
  margin: 16px 0 60px;
  display: flex;
  .recordSummarySection {
    flex: 1;
    font-family: $global-font-light;
    color: $light_1_dark_navy;
    align-self: center;
  }
  nav {
    flex: 1;
    justify-content: flex-end;
  }
  .ratekeyGrid & , .rateDetailsList &{
    margin-bottom: 20px;
  }
}