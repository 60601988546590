@import '../../../assets/theme/colors.scss';
@import '../../../assets/theme/globalFonts.scss';
.fc-filterTable {
  display: flex;
  flex-direction: column;

  .fc-filterRow {
    display: flex;
    margin-bottom: 12px;
    &:first-child {
      margin-bottom: 8px;
    }
    &:last-child {
     margin-bottom: 0;
    }

    .fc-filterCell,
    .fc-actionCell,
    .fc-valueCell {
      flex-grow: 1;  
      margin: 0 20px 0 0;
      flex-basis: 20%;
      font-family: $global-font-demibold;
    }
    .fc-filterCell {
      .fasatDropdown > div > label {
        display: none;
      }
      div {
        line-height: 0.9;
        div[id$='-value'] {
          line-height: 1.3;
        }
      }
      width: 18%;
    }
    .fc-actionCell {
      display: flex;
      flex-basis: 10%;
      button {
        border: none;
        background: transparent;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        margin: 12px 4px 0;
        height: 20px;
        padding: 0;
        width: 20px;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          color: $m_green;
        }
        &:active {
          color: $dark_2_green;
        }
      }
    }
    .fc-valueCell {
        flex-basis: 50%;
        margin-right: 8px;
        .fasatDropdown > div > label {
          display: none;
         }
        .fasatDropdown {         
          button {
            margin-top: 0;
          }
        }
        .fasatDatePicker {
          div[class^="styledComponents__LabelWrapper"] {
            display: none;
          }
        }
        .dateRange {
          display: flex;
          .fasatDatePicker {
            width: 50%;
          }
          .hyphen {
            margin: 8px;
          }
        }
        .inputRange {
          display: flex;
          .fasatTextInput {
            width: 50%;
          }
          .hyphen {
            margin: 8px;
          }
        }
    }
  }
}

@media screen and (max-width: 763px) {
  .fc-filterTable {
    .fc-filterRow {
      display: block;
      .fc-filterCell {
        width: 308px;
      }
      .fc-valueCell {
        margin-right: 0;
        width: 336px;
        display: inline-block;
      }
      .fc-actionCell {
        justify-content: center;
        margin-right: 0;
        display: inline-block;
        margin-left: 8px;
        vertical-align: top;
        margin-top: 25px;
        button {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .fc-filterTable {
    .fc-filterRow {
      display: block;
      .fc-filterCell {
        width: 100%;
      }
      .fc-valueCell {
        margin-right: 0;
        width: 100%;
      }
      .fc-actionCell {
        justify-content: center;
        margin: 0;
        display: flex;
        button {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}