/* Manulife JH Sans - Deprecate */

@font-face {
  font-family: 'Manulife JH Sans-Light';
  src: url('ManulifeJHSans-Optimized-Light.woff2') format('woff2'),
    url('ManulifeJHSans-Optimized-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans-Regular';  
  src: url('ManulifeJHSans-Optimized-Light.woff2') format('woff2'),
    url('ManulifeJHSans-Optimized-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans-Demibold';  
  src: url('ManulifeJHSans-Optimized-Demibold.woff2') format('woff2'),
    url('ManulifeJHSans-Optimized-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans-Bold';  
  src: url('ManulifeJHSans-Optimized-Bold.woff2') format('woff2'),
    url('ManulifeJHSans-Optimized-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
