@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatTab {
  position: relative;
  .prevBtn, .nextBtn {
    position: absolute;
    top: 10px;
    button {
      padding: 0;
      font-size: 2em;
    }
  }
  .prevBtn {
    left: -30px;
  }
  .nextBtn {   
    right: 0;
  }
  ul[data-testid="tablist"] {
    justify-content: left;
    margin-left: -32px;
    margin-right: -32px;
    padding-right: 64px;
    border-bottom: none;
    width: 100%;
    overflow-x: auto;
    background-color: $light_grey;
    height: 60px;
    li {
      button {
        font-family: $global-font;
        margin: 0 20px;
      }
      &:first-child {
        button {
          margin-left: 30px;
        }
      }
    }
    .dailyProcessSubTab & {
      margin: 0;
      background-color: $white;
      border-bottom: 4px solid $light_grey;
      li {
        button {
          font-family: $global-font;
          margin-right: 40px;
          margin-left: 0px;
        }
        &:first-child {
          button {
            margin-left: 0px;
          }
        }
      }
    }
  }
  // .sysOptTabsContainer, .gridBarContainer {
  //   .fasatGridBar {
  //     padding: 32px 0 20px;
  //     padding-top: 0;
  //     margin-top: 32px;
  //     .actionSection {
  //       button {
  //         margin-top: 0;
  //       }
  //     }
  //   }
  // }
  div[id^='tabpanel-'] {
    background: $white;
  }
  .tabRuler {
    margin: 40px 0 30px;
    border-top: 1px solid $light_grey;
  }
  .rateMaster {
    margin-top: 20px;
    &.ratekeylist {
      .allViewBtnSection {
        margin: 0;
        text-align: right;
      }
    }
  }
}
.addEditDeductions, .addEditPayment {
  h2 {
    margin-top: 32px;
  }
  form-row, .form-row {
    .form-element {
      &.twoColumnRow {
        width: 636px;
      }
    }
  }
}
.addEditNotifyEventsForm {
  label{
    margin-bottom: 8px !important;
  }
  .form-row {
    .form-element {
      width: 308px;
      margin-right: 20px;
      &.addRmvBtn {
        margin-top: 40px;
      }
    }
  }
}
.detailsContainer {
  margin: 32px 0;
  font-family: $global-font;
  label {
    font-family: $global-font;
    margin-bottom: 0;
    span.dateFormatSpan {
      display: inline-block;
      font-weight: 300;
    }
  }
  &.addEditCloneFinArrForm {
    margin-top: 32px;
    h2 {
      margin-top: 32px;
      margin-bottom: 20px;
    }
    label {
      margin-bottom: 8px;
    }
  }
  // .formControl{
  //   margin-bottom: 20px;
  //   &:last-child{
  //     margin-bottom: 0;
  //   }
  //   &.datePickerControl{
  //     label{
  //       margin-bottom: 8px;
  //     }
  //     button {
  //       bottom: 2px;
  //     }
  //   }
  //   &.editFormControl {
  //     label {
  //       margin-bottom: 8px;
  //     }
  //   }
  //   &.checkBoxControl {
  //     &.checked {
  //       label {
  //         font-weight: normal;
  //       }
  //     }
  //     span[class^='styledComponents__CheckboxWrapper']{
  //       margin: 0 10px 0 0;
  //     }
  //   }
  //   &.earlySwtchTime {
  //     width: 332px;
  //     .form-element {
  //       padding-right: 0;
  //       margin-right: 0;
  //     }
  //     .colon {
  //       padding: 0 12px;
  //       vertical-align: -webkit-baseline-middle;
  //       display: inline;
  //       font-weight: 600;
  //     }
  //   }
  // }
  .rowContainer {
    display: flex;
    margin-bottom: 20px;      
    .columnContainer {
      width: 308px;
      margin-right: 20px;
      display: block;        
      &.btnSelectorControl {
        label{
          margin-bottom: 8px;
        }
      }
    }
    .columnContainerHidden {
      display: none;
    }
    &.addedRoleRow {
      margin-bottom: 12px;
      &.inEditScreen {
        margin-bottom: 0;
        padding: 12px 8px;
        border-bottom: 4px solid #EDEDED;
        border-top: 4px solid #EDEDED;
        .columnContainer {
          .sdCheckbox {
            background: rgb(255, 255, 255);
            border-style: solid;
            border-width: 1px;
            border-color: rgb(94, 96, 115);
            width: 28px;
            height: 28px;
            vertical-align: bottom;
            margin-left: 3px;
            cursor: pointer;
            border-radius: 0;
            appearance: none;
            &:hover, &:focus {
              border-width: 2px;
            }
            &:checked {
              border: 2px solid rgb(0, 167, 88);
              background: no-repeat #ffffff url("../../assets/images/checkmark.png") 5px 7px;
            }
          }
          label {
            margin-top: 5px;
          }
          &.col1 {
            width: 60px;
          }
          &.col2 {
            width: 280px;
          }
          &.col3 {
            width: 560px;
          }
        }
      }
      
    }
  }
  .editSD {
    .rowContainer {
      &.inEditScreen {
        border-top: 0;
        border-bottom-width: 2px;
      }
    }
  }
  &.topContainer {
    margin-top: 0;
    .rowContainer:first-child {
      margin-top: 0;
    }
  }
  &.sysOptionDetails, &.addEditClientAddForm, &.addEditCloneFinArrForm, &.editNotifyUsers, &.addEditNotifyEventsForm, &.addEditClientLicenceForm, &.addEditCliStrutForm, &.addEditDeductions  {
    margin-top: 0;
    .addEditCliStrutFormWrapper{
      margin-bottom: 80px;
    }
    .groupCheckbox {
      margin-bottom: 20px;
    }
    .form-row {
      .form-element {
        margin-right: 20px;
        padding-right: 0;
        &.tabColumn {
          width: 308px;
        }
        &.commentColumn{
          width: 636px;
          margin-top: 12px;
        }
        &.twoColumnRow{
          width: 636px;
        }
        &.otherReqBtnCtrl, &.cliAddSelectBtnCtrl{
          .fasatButtonSelector{
            div[data-testid="horizontal-wrapper"]{
              height: 48px;
              margin-top: 8px;
            }
          }
        }
        &.noWrapText {
          white-space: nowrap;
        }
        &.tabColumnGrpCheckbox {
          margin-left: 20px;            
          white-space: nowrap;
        }
      }
      &.checkBoxFormRow {
        padding-bottom: 16px;
      }
    }
    .cliDetailsButtonSection {
      padding-top: 20px;
    }
    .fasatCheckbox .labelContent {
      vertical-align: 3px;
    }
    &.sysOptionDetailsEdit {
      margin-top: 32px;
    }
  }
  &.addEditClientAddForm, &.addEditClientLicenceForm, &.editNotifyUsersForm, &.addEditNotifyEventsForm  {
    h2{
      margin-top: 32px;
    }
  }
  &.addEditCliStrutMargin {
    margin-top: 32px;
  }
  &.viewCliStrutDetails{
    margin-top: 0;
  }
  &.clientAddressContainer {
    margin-top: 0;
  }
  .heading2 {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: $light_1_dark_navy;
    margin-bottom: 20px;
  }
  &.backgrndProcess{
    margin-top: 12px;
    margin-bottom: 40px;
  }    
  .rowContainerHidden {
    display: none;
  }
}
.accessOptionsList, .eventsList, .usersList, .autoReportsTabList {
  .filterSettingsPanel {
    margin-top: 32px;
  }
}
.securityTab, .assoTransTab {
  min-height: 465px;
}
.compStructTreeTab {
  margin-bottom : 31px;
}
.fieldForceSubTab {
  padding: 32px 0;
}
.addEditClientLicenceForm {
  .form-row.noPaddingBottom {
      padding-bottom: 0;
  }
}
.addEditPayment {
  margin-top: 32px;
}
.prodSummaryListHTML {
  width: 100%;
  overflow-x: auto;
  table {
    font-family: "Manulife JH Sans","Helvetica","Arial",sans-serif;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #34384b;
    border-top: solid 4px #EDEDED;
    border-collapse: collapse;
    tr {
      border-bottom: 2px solid #EDEDED;
      &.highlightedRow, &.selectedRow {
        background: #EDEDED !important;
      }
      th {
        text-align: left;
        font-weight: 600;
        vertical-align: bottom;
        padding: 8px;
        white-space: pre;
        &:first-child {
          padding-left: 16px
        }
        .headerLblUnbold {
          font-weight: 300;
          padding: 2%;
        }
      }
      td {
        padding: 8px;
        vertical-align: text-top;
        font-weight: 400;
        white-space: pre;
        &:first-child {
          padding-left: 16px
        }
      }
    }
  }
}

#addEditFFTask {
  .addEditTaskTab {
    padding-top: 32px;
    h3 {
      font-size: 22px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      margin: 12px 0 20px;
      color: $light_1_dark_navy;
    }
    .tabFooterBtn {
      .left {
        padding-left: 0;
      }
    }
    .noDisplayBR {
      display: none;
    }
    .displayBR {
      display: block;
    }
  }
  .taskCol {
    display: inline-block;
    width: 308px;
    margin-bottom: 20px;
    margin-right: 20px;
    vertical-align: top;
    &.taskCol1, &.taskCol4, &.taskCol7, &.taskCol10 {
      display: block;
    }
    &.lastRowTaskCol {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .fasatTab {
    .prevBtn, .nextBtn {
      display: block;
    }
  }
}

@media screen and (min-width: 1025px) {
  .fasatTab {
    .prevBtn, .nextBtn {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) {
  .fasatTab {
    ul[data-testid="tablist"] {
      margin-right: -24px;
    }
  }
}