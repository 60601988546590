@import '../../../../assets/theme/colors.scss';

.taGrid {
  tr {  
    th, td {
      white-space: nowrap;
    }
  }
}

.notesListContainer, .eventsListContainer {
  tr {  
    th, td {
      white-space: nowrap !important;
    }
  }
}


tr.defaultRow {
  font-weight: normal;
  background-color: $light_2_grey;
  td {
    &:first-child {
      border-left: 4px solid $blue;
      padding-left: 12px;
    }
  }
}

.batchExportGrid {
  position: relative;
  tr {
    th:last-child, td:last-child {
      right: 0 !important;
    }
    th:last-child {
      padding-left: 4% !important;
    }
  }
  div[data-testid="CustomGridComponent"] {
    width: 87%;
  }
  .footerBarWrapper {
    margin-bottom: 0;
  }
}

.compStrucPage, .PD, .clientAddListContainer, .finArrContainer {  
  [data-testid="CustomGridComponent"] {
    width: 87%;
    th:last-child {
      width: 108px !important;
    }
    td:last-child {
      min-width: 102px !important;
    }
  }
}

.padingSubTab {
  padding: 32px 0 0 0;
}
.padingSubTab1 {
  padding: 12px 0 32px 0;
}

.dailyProcessSubTab {
  padding: 32px 0px 0px 0px;
}


.manageGrp, .assoTransTab, .groupByGrid, .process,
.processBatch, .scheduleTab, .rateSweepBendingList, .fieldForceMovementList, .autoReportsTabList, .manageLevel, .CopySweepBandingList, .externalRateList {
  [data-testid="CustomGridComponent"] {
    overflow-x: hidden;
    margin-bottom: 80px;
    margin-top: 20px;
    width: 100%;
    th:last-child {
      position: relative !important;
      // right: 0;
      text-align: left;
      box-shadow: none !important;
      padding-left: 8px;
      padding-right: 16px;
    }
    td:last-child {      
      position: relative;
      // right: 0;
      text-align: left;
      box-shadow: none !important;
      padding-left: 8px;
      padding-right: 16px;
    }
  }
}
.rateSweepBendingList, .dtsMonitorList, .autoReportsTabList, .fieldForceMovementList, .FieldforceMembersList, .CopySweepBandingList, .externalRateList{
  [data-testid="CustomGridComponent"] {
    overflow-x: auto;
    margin: 0;
    th:last-child, td:last-child {
      padding-left: 8px;
      padding-right: 16px;
    }
  }
}
.groupByGrid {
  [data-testid="CustomGridComponent"] {
    width: 100% !important;
    overflow-x: auto;
    td.amountCell {
      padding-right: 50px;
    }
    th:last-child, td:last-child {
      right: 5px;
    }
  }
}
.scheduleTab, .process {
  [data-testid="CustomGridComponent"] {
    margin-bottom: 40px;
    td:last-child {      
      position: relative;
      // right: 0;
      text-align: left;
      box-shadow: none !important;
      width: 400px !important;
      .fasatCheckbox {
        width: max-content;
      }
    }
  }
}
.dailyProcessFcr {
  [data-testid="CustomGridComponent"] {
    margin-bottom: 40px;
  }
}

.batchNumberCopyButton {
  text-align: end;
  margin-bottom: 22px;
}

.TA, .rateTableGrid, .prodSummaryList, .ffBalPrd, .ffBalMnt, .ffBalSum, .massAgt, .ffBalHier, .ffProdHierView,
 .ffProdProduct, .ffProdProdLwr, .ffProdHierLwr, .ffBalHierGrid, .ffProdBussView, .dp-statements-xmllife-tab,
 .rateDetailsAllGridy, .dtsMonitorList, .licence-list, .select-letters, .AP-list, .ff-letter-list, .ffCheckProcessing, .AUP-list,
 .fieldForceMovementList, .autoReportsTabList, .fpa-monitoring-screen, .FieldforceMembersList, .CopySweepBandingList, .externalRateList {
  [data-testid="CustomGridComponent"] {
    width: 100%;
    th:last-child {
      position: relative !important;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
    }
    td:last-child {      
      position: relative;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
      background-color: inherit;
    }

  }
}
.activityProcessed {
  [data-testid="CustomGridComponent"] {
    width: 100%;
    th:last-child {
      position: relative !important;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
    }
    td:last-child {      
      position: relative;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
      background-color: inherit;
    }
  }
}
.massAgt {
  [data-testid="CustomGridComponent"] {
    td:last-child {
      border-right: 0;
    }
  }
}
.compstatementlist {
  [data-testid="CustomGridComponent"] {
    width: 100%;
    th:last-child {
      position: relative !important;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
    }
    td:last-child {      
      position: relative;
      padding-left: 8px;
      padding-right: 16px;
      right: 0;
      background-color: inherit;
    }
    th:first-child {
      position: relative !important;
      padding-left: 0px !important;
      margin-left: 0px;
      z-index: 0;
    }
    td:first-child {      
      position: relative !important;
      padding-left: 0 !important;
      margin-left: 0px;
      background-color: inherit;
    }
  }

}
.manageLevel.extFasatPage {
  [data-testid="CustomGridComponent"] {  
    overflow-x: auto;
  }
}
.ffBalPrdExportBtn{
  text-align: right;
  padding: 20px 0 12px;
  button:disabled{
    color: #5e6073;
    pointer-events: none;
  }
}
.dailyProcessSch .process {
  [data-testid="CustomGridComponent"] {
      margin-bottom: 40px;
      overflow-x: auto;
}
}

.dailyProcessSch .batch {
  [data-testid="CustomGridComponent"] {
    margin-bottom: 40px;
      overflow-x: auto;
}
}

.dailyProcessFcr .history{
    [data-testid="CustomGridComponent"] {
    overflow-x: auto;
    margin-top: 20px;
    margin-bottom: 0px !important;
    width: 100%;
    th:last-child {
      position: relative !important;
      padding: 8px;
      text-align: left;
      box-shadow: none;
    }
    td:last-child {      
      position: relative;
      // right: 0;
      text-align: left;
      padding: 8px;
      box-shadow: none;
    }
}
}

.scsTotalDetails {
  font-size: 32px;
}

.visibilityHidden {
  visibility: hidden;
}


@media screen and (min-width: 1281px) {
  .compStrucPage, .PD, .clientAddListContainer {  
    [data-testid="CustomGridComponent"] {
      width: 89.5%;
    }
  }
}