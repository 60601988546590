@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.signOutSection {
    text-align: center;
    font-family: $global-font;
    color: $light_1_dark_navy;
    margin: 160px auto 254px;
    img {
        vertical-align: bottom;
    }
    h1 {
        margin: 0;
        padding-top: 20px;
        padding-bottom: 8px;
        font-size: 32px;
        font-weight: 300;
        line-height: 1.31;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: normal;
        margin: 0;
    }
}