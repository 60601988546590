@import '../../assets/theme/globalFonts.scss';
@import '../../assets/theme/colors.scss';

.fasatConfirmMsg {
  font-family: $global-font;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $light_1_dark_navy;
  .msgInGreen {
    color: $link_green;
  }
}

.modalWithoutHeading {
  .fasatConfirmMsg {
    margin-top: -24px;
  }
}