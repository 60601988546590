@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatSecuritySettings {
  &.inViewMode {
    margin-bottom: 60px;
  }
  table {
    font-family: $global-font;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $light_1_dark_navy;
    border-top: solid 4px $light_grey;
    border-collapse: collapse;
    margin-bottom: 12px;

    tr {      
      border-bottom: 2px solid $light_grey;
      th {
        border-bottom: 2px solid $light_grey; 
        font-weight: 600;
        vertical-align: bottom;
        padding: 8px;
        .columnHeaderText {
          word-break: break-word;
          margin-right: 4px;
        }
        &:last-child {
          padding-right: 16px;
          // width: 200px;
        } 
        &:first-child {
          padding-left: 16px;
          width: 432px;
        }
      }
      td {
        padding: 8px;
        vertical-align: inherit;
        .fasatCheckbox {
          label {
            margin: 0 auto;
          }
        }
        &:last-child {
          padding-right: 16px;
          text-align: right;
          button:first-child {
            margin-right: 8px;
          }
        } 
        &:first-child {
          padding-left: 16px;
        }
      }
    }
  }
}

.btnAsLink {
  cursor: pointer;  
  font-size: 16px;  
  text-decoration: underline;  
  color: $link_green;  
  border: 0;  
  background: none;  
}