@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatRouterLink {
  a {
    font-family: $global-font;
    color: $link_green;
  }
}

.jobStatusScreen {
  text-decoration: none !important;
  color: #34384B !important;
  font-size: 16px !important;
}