@import '../../assets/theme/colors.scss';

div[id$='-value'] {
  font-weight: 400;
}
div [aria-describedby$="-errors"] {
  border-width: 2px;
}

.fasatDropdown {
  button[disabled] {
    border: 1px solid $dark_3_light_grey;
  } 
  &.withError {
    select, button {
      border-color: $red_error;
      border-width: 2px;
    }
  }
}