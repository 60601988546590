@import '../../assets/theme/globalFonts.scss';

.Toastify__toast-container {
  margin: 0 16px;
  // min-width: 320px;
  background: none;
  &.Toastify__toast-container--top-right {
    top: 72px;
    z-index: 999999;
  }
  &.Toastify__toast-container--bottom-center {
    width: 100%;  
    margin: 0 auto;
    padding: 32px;
    .fasatQuickActionNotification{
      // margin: 0;
      padding: 0;
      .Toastify__toast-body {
        width: 100%;
        padding: 0;
        .iconContainer {
          width: 100%;
        }
      }
    }    
  }
}
.fasatToastNotification {
  padding: 10px;
  background-color: #34384b;
  width: 330px;
  .messageContainer {
    display: flex;
    .message {
      margin: 0 0 0 12px;
      font-family: $global-font;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      background-color: #34384b;
    }
  }
}
