@import '../../assets/theme/colors.scss';
.fasatCheckbox {
  .labelContent {
    display: inline-block;
  }
  &.withError {
    input {
      border-color: $red_error;
      border-width: 2px; 
    }
  }
  svg {
    margin-top: 1px;
  }
  &.checked {
    label {
      font-weight: normal;
    }
  }
}

.sdCheckbox {
  background: rgb(255, 255, 255);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(94, 96, 115);
  width: 28px;
  height: 28px;
  min-width: 28px;
  vertical-align: -8px;
  margin: 3px 10px 3px 3px;
  cursor: pointer;
  border-radius: 0;
  appearance: none;
  &:hover, &:focus {
    border-width: 2px;
  }
  &:disabled {
    border: 1px solid rgb(194, 195, 201);
    cursor:default;
  }
  &:checked {
    border: 2px solid rgb(0, 167, 88);
    background: no-repeat #ffffff url("../../assets/images/checkmark.png") 5px 7px;
    &:disabled {
      border: 1px solid rgb(194, 195, 201);
      cursor:default;
      background: no-repeat #ffffff url("../../assets/images/checkmarkDisable.svg") 5px 7px;  
    }
  }
}
.indicator{
   font-size: 20px;
   font-weight: bold; 
}