@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatHTMLgrid {
  font-family: $global-font;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $light_1_dark_navy;
  caption {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }
  thead {
    tr {
      th {
        font-family: $global-font-demibold;
        border-top: 3px solid $light_grey;
        border-bottom: 3px solid $light_grey;
        padding: 12px 0;
        &:nth-child(2) {
          width: 240px;
        }
        &:nth-child(3) {
          width: 300px;
        }
        &:nth-child(4) {
          width: 300px;
        }
        &:nth-child(1) {
          width: 80px;
        }
      }
    }
    tr.activity {
      th {
        font-family: $global-font-demibold;
        border-top: 3px solid $light_grey;
        border-bottom: 3px solid $light_grey;
        padding: 12px 0;
        width: 180px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 0;
        border-bottom: 1px solid $light_grey;
        .AUP-list & {
          padding: 12px 12px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
      &.stRowHighlight {      
        font-family: $global-font-demibold;
      }
    }
  }
}
.AUP-list {
  width: 100%;
  overflow-x: auto;
  .fasatHTMLgrid {
    width: max-content;
    thead {
      tr {
        th {
          padding: 12px 12px;
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            width: auto;
          }
          &:nth-child(1) {
            padding-left: 0;
          }
        }
      }
    }
  }
}