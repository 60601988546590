@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';
  
.form-row {
  .form-element {
    &.twoColumnRow {
      width: 636px;
    }
  }
}

.addCompButtonSection, .addEditEventBtnSection {
  button {
      margin-right: 20px;
  }
  margin-top: 12px;
  margin-bottom: 60px;
}

.addFFtaskButtonSection {
  button {
      margin-right: 20px;
  }
  margin-top: 32px;
  margin-bottom: 60px;
}

.lroButtonSection {
  button {
      margin-right: 20px;
  }
  margin-top: 32px;
  margin-bottom: 60px;
}

.processScreen {
  button {
    margin-right: 20px;
}
margin-top: 20px;
margin-bottom: 40px;
}
.dTprocessScreen {
  button {
    &:first-child {
      width: 200px;
    }
    margin-right: 20px;
}
margin-top: 20px;
margin-bottom: 40px;
}

#manualAdjustmentsForm {
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }
}

.addEditLRO {
  .form-element {
    .optionalLabel {
      margin-bottom: 7px !important;
    }
  }
}

.salesCreditSearch {
  .form-row {
    .form-element {
      width: 308px;
      margin-right: 20px;
      padding: 0;
    }
  }
  .salesCreditBtnSection {
    padding-top: 12px;
    button {
      margin-right: 20px;
    }
  }
}
.fasatPage {
  height: 100%;
  min-height: 90vh;
  padding: 20px 32px 40px 112px;
  background-color: $white;
  ::-webkit-scrollbar {
    width: 8px;
  }
  &.bcDetailPage {
    min-height: 100vh;
  }
  .pageHeaderBtnSection {
    display: flex;
    div {
      text-align: left;
      flex: 50%;
      button {  
      padding: 0 0 20px 0;
      }
    }
    div.actionBtnSection {
      text-align: right;
    }
  }
  &.errorGen {
    padding-bottom: 150px;
  }
  &.stepperPage {
    background-color: $light_2_grey;
  }
  #manualAdjustmentsForm {
    padding-top: 32px;
    border-top: 2px solid $light_grey;
  }
  .addEditRateMasterForm {
    .formRow {
      margin-bottom: 20px;
      .fasatCheckboxGroup {
        div[direction="row"] {
          padding: 0;
          margin: 0;
          fieldset {
            margin: 0;
            legend {
              display:none;
            }
            div[direction="row"] {
              width: 988px;
            }
          }
        }
        div[data-testid^="checkbox-wrapper"] {
          margin-bottom: 20px;
        }
      }
      &.chkBoxRow {
        margin-bottom: 0;
      }
    }
    .fasatTextInput {
      #id33-list {
        position: absolute;
        width: 100%;
        margin-top: 48px;
        z-index: 9;
        border-color: $dark_3_light_grey;
        max-height: 320px;
        overflow-y: auto;
      }
    }
    .h2Section {
      margin-top: 40px;
    }
    .rateMasterChkboxLabel {
      label {
        margin-bottom: 13px;
      }
    }
    .formButtonSection {
      margin-top: 12px;
      margin-bottom: 80px;
      button {
        margin-right: 20px;
      }
    }
  }
  .dp-statements-xmllife-tab{
    .printAwaitingReprt-text{
      margin-top: 35px;
      margin-bottom: 35px;
    }
    .display-batch-rpt-btns{
      display:block;
    }
    .hide-batch-rpt-btns{
      display:none;
    }
    margin-top: 32px;
    padding-top: 30px;
    .addCompButtonSection{
      #stopGenerateReportBtn{
        padding-right: 20px;
      }
    }
    .fasatCheckbox  {
      // position: absolute;
      // top: 40px;
      margin-top: 35px;
      .labelContent  {
        label {
          position: relative;
          top: -5px;
        }
      }
    
    }
    .batchDrpDwnContainer{
      width: fit-content;
    }
 
  }
}

#generalPrefForm {
  padding-bottom: 32px;
}

.fasatFooter {
  margin-left: 80px;
  .fasatContactUsFooter {
    display: flex;
    .contactUsFooter {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      background-color: $m_green;
      font-family: $global-font-demibold;
      span{        
        color: $white;
        text-decoration: none;
        font-size: 19px;
        font-weight: 700;
        padding-top: 0;
        &:first-child {
          margin-right: 10px;
        }
        svg {
          margin-top: 2px;
        }
      }
      &:first-child {
        margin-right: 1px;
      }
      &:last-child {
        margin-left: 1px;
      }
    }
  }
}

.notAuthorizedSection {
  margin-top: 220px;
  margin-bottom: 316px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: $global-font;
  color: $red_error;
}

#addEditCompCode, #addEditFinArrForm {
  .addEditCStoDate {
    div[class^='styledComponents__LabelWrapper'] {
      display: none;
    }
    label {
      margin-bottom: 0;
    }
  }
}

.licence-change-status-modal{
  #licenceChangeStatusDropDown{
    margin-top: 20px;
  .licenceChangeStatusList-label + div {
    width: 90%;
  }
}
  .modalBtnContainer{
    margin-top: 45px;
    // display: flex;
    // flex-direction: row-reverse;
  }
}

.select-letters{
  .letter-address-container{
    margin-top: 32px;
    margin-bottom: 15px;
  }
.download-letter-btn{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  #downloadLetterBtn{
     svg{
      height: 25px;
      width: 25px;
     }
  }
}
}

.fasatPage.extFasatPage {
  // .fasatButtonSelector {
  //   width: 100%;
  // }

  .viewChMasterHeader {
    display: none;
  }

  .fasatQuickLinks {
    width: 99.8%;
  }
  .fasatBatchExport {
    width: 100%;
    margin-top: 20px;
    .batchExportGrid div[data-testid=CustomGridComponent] {
      width: 92%;
      tr th:last-child {
        padding-left: 3.2% !important;
      }
    }
  }
  .currPerEarnSection {
    margin-top: 20px;
    .earnPeriodDiv {
      font-weight: 400;
    }
    .earningDetailsSec {
      margin-top: 20px;
      .earningDetails {
        display: inline-block;
        width: 200px;
        margin-right: 16px;
        margin-bottom: 20px;
        vertical-align: top;
        span {
          vertical-align: bottom;
        }
      }
    }
  }
  .compStmtDashboard {
    margin-top: 12px;
    div[class^="styledComponents__BodyWrapper"] {
      padding-top: 20px;
      .fasatGridBar {
        padding-top: 20px;
        padding-bottom: 12px;
      }
    }
  }
  .extFasatBtn {
      button:first-child {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  .qsAsearchContainer {
    .fasatButtonSelector {      
      width: 636px;
      margin-bottom: 20px;
    }
    .extFasatBtn {
      button:first-child {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
    .quickSearchSection{
      .searchFormRow {
        .searchFormElement {
          display: inline-block;
          width: 308px;
          margin-right: 20px;
          margin-bottom: 20px;
          vertical-align: top;
          &:last-child {
            margin-right: 0;
          }
          .fasatDatePicker {
            div[class^="styledComponents__LabelWrapper"] {
              display: none;
            }
          }
        }
      }
      .searchForm {
        .fasatDropdown button {
          margin-top: 8px;
        }
      }
    }
    .advancedSearchSection{
      margin-top: 20px;
    }
    .searchFilterAdvance{
      display: none;
    }
    .searchFilterBlock{
      display: block;
    }
    .searchFilterNone{
      display: none;
    }
  }
  .viewBrandedRatesSection {
    margin: 12px 0 8px;
    display: flex;
    .viewRatesSection, .brandedRatesSection {
      flex: 1;
    }
    .viewRatesSection {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .fasatPage.extFasatPage {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 80px;
    .batchExportGrid div[data-testid=CustomGridComponent] {
      width: 92%;
      tr td:last-child {
        padding-left: 2.2% !important;
      }
    }
  }
  .fasatFooter {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .fasatPage.extFasatPage {
    padding-left: 24px;
    padding-right: 24px;
    .batchExportGrid div[data-testid=CustomGridComponent] {
      width: 92%;
      tr td:last-child {
        padding-left: 1.5% !important;
      }
    }
    .qsAsearchContainer {
      .fasatButtonSelector {      
        width: 100%;
      }
      .quickSearchSection{
        width: 100%;
        .searchForm {
          .searchFormRow {
            .searchFormElement {
              width: 100%;
            }
          }
        }
      }
    }
    .viewBrandedRatesSection {
      display: block;
      .viewRatesSection, .brandedRatesSection {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
}

.advisor-details-tabs-ext{
  padding-top: 20px;
  margin-top: 20px;
}

.detailedActivity{
  padding: 0;
}
@media screen and (max-width: 599px) {
  .fasatPage.extFasatPage {
    padding-left: 24px;
    padding-right: 24px;
    .fasatBatchExport {
      .batchExportGrid div[data-testid=CustomGridComponent] {
        width: 86%;
        tr td:last-child {
          padding-left: 3.5% !important;
        }
      }
    }
    .qsAsearchContainer {
      .fasatButtonSelector {      
        width: 100%;
      }
      .quickSearchSection{
        width: 100%;
        .searchForm {
          .searchFormRow {
            .searchFormElement {
              width: 100%;
            }
          }
        }
      }
    }
    .viewBrandedRatesSection {
      display: block;
      .viewRatesSection, .brandedRatesSection {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
    .viewChMasterHeader {
      display: block;

    }
    .hideMasterHeader {
      display: none;

    }
  }
  .fasatFooter {
    .fasatContactUsFooter {
      display: block;
      .contactUsFooter {   
        &:first-child {
          margin-bottom: 1px;
          margin-right: 0;
        }
        &:last-child {
          margin-top: 1px;
          margin-left: 0;
        }
      }
    }
  }
}

