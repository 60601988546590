@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatStatementGrid {
  font-family: $global-font;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $light_1_dark_navy;
  thead {
    tr {
      th {
        font-family: $global-font-demibold;
        // border-bottom: 3px solid $light_grey;
        text-align: right;
        padding-bottom: 12px;
        margin-bottom: 10px;
        &:first-child {
          // visibility: hidden;
          font-size: 0;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: right;
        padding: 6px 0;
        height: 24px;
        border-bottom: 1px solid $light_grey;
        &:first-child {
          text-align: left;
        }
      }
      &.stRowHighlight {      
        font-family: $global-font-demibold;
      }
      &:first-child {
        td {
          border-top: 3px solid $light_grey;
        }
      }
    }
  }  
}