@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';



.ffBalanceProdTabLand, .intActRepLand {
  .periodForm, .monthlyForm, .summaryForm, .hierarchyForm, .bussViewForm, .hierViewForm, .productForm, .prodHierForm, .intActRepSrForm, .orgSecurityForm, .codeHierForm {    
    margin: 8px 0 14px;
    .formRow {
      margin-bottom: 20px;
      .formElement {
        display: inline-block;
        width: 308px;
        margin-right: 20px;
        &.twoColumnRow {
          width: 636px;
        }
        &.chkBoxEle {
          vertical-align: -18px;
        }
      }
      .fasatCheckboxGroup {
        div[direction="row"] {
          padding: 0;
          margin: 0;
          fieldset {
            margin: 0;
            legend {
              display:none;
            }
            div[direction="row"] {
              width: 988px;
            }
          }
        }
        div[data-testid^="checkbox-wrapper"] {
          margin-bottom: 20px;
        }
      }
      &.ddRow {        
        margin-bottom: 14px;
      }
      &.chkBoxRow {
        margin-bottom: 0;
      }
    }
    .formBtnContainer {
      margin-top: 12px;
      &.updated {
        margin-top: 24px;
      }
    }
  }
  .prodHierForm {
    .formBtnContainer {
      margin-top: 12px;
    }
  }
  .tableSection {
    width: 48%;
    margin-right: 40px;
    display: inline-block;
    vertical-align: top;
    &:last-child {
      margin-right: 0;
    }
  }
  .orgSecurityForm {
    margin-top: 20px;
    .formRow {
      .formElement {
        vertical-align: top;
      }
    }
    .formBtnContainer {
      margin-bottom: 32px;
      margin-top: 32px;
      button {
        margin-right: 20px;
      }
    }
  }
  .codeHierForm {
    .formRow {
      .formElement {
        vertical-align: top;
      }
    }
    .formBtnContainer button {
      margin-right: 20px;
    }
  }
}

.intActRepLand {
  .fasatExpandablePanel{
    margin-bottom: 20px;
  }
  .intActRepSrForm {
    .formRow {
      .formElement {
        vertical-align: top;
      }
    }
    .formBtnContainer {
      button {
        margin-right: 20px;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.fasatTab {
  ul[data-testid="tablist"] {
    &::-webkit-scrollbar { 
      width: 0;
      height: 0;
    }
    .fasatSubTab & {      
      background-color: transparent;
      border-bottom: 4px solid $light_grey;
      margin-left: 0;
      padding-right: 0;
      overflow-x: auto;
      li{
        &:first-child {
          button {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .ffBalanceProdTabLand {
    .tableSection {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 32px;
      .fasatStatementGrid {
        width: 550px;
      }
    }
  }
  .codesAndHierTreeView {
    width: 100%;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1024px) {
  .ffBalanceProdTabLand, .intActRepLand {
    .productForm, .bussViewForm, .periodForm, .monthlyForm, .intActRepSrForm {
      .formRow {
        margin-bottom: 0;
        .formElement {
          margin-bottom: 20px;
          &.twoColumnRow {
            width: 308px;
            div[data-testid="horizontal-wrapper"] {
              flex-direction: column;
            }
          }
        }
        &.ddRow {
          margin-bottom: 0;
          .formElement {
            margin-bottom: 14px;
          }
        }
      }
    }
  }  
}


@media screen and (max-width: 873px) { 
  .ffBalanceProdTabLand {
    .monthlyForm {
      .formRow {
        .formElement {
          div[data-testid="horizontal-wrapper"] {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .ffBalanceProdTabLand, .intActRepLand {
    .productForm, .bussViewForm, .periodForm, .monthlyForm, .intActRepSrForm {
      .formRow {
        .formElement {
          width: 100%;
          &.twoColumnRow {
            width: 100%;
          }
        }
      }
      .formBtnContainer {
        button {
          width: 100%;
        }
      }
    }
  }  
}
