/**
 * @Description Alignment related stylings
 * @FileName alignment.scss
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 08 February, 2021 07:40:39
 * @IssueID 325
 */
/* Alignment */
.f-align-left{
  text-align:left;
}
.f-align-right{
  text-align:right;
}

/* Margin */
.f-margin-b-12 {
  margin-bottom: 12px;
}
.f-margin-t-30 {
  margin-top: 30px;
}

/* Padding */
.f-padding-b-12 {
  padding-bottom: 12px;
}

.f-w-240 {
  width: 240px;
}

ul[role=menu] {
  li:last-child {
    display: none;
  }
}