@import '../../assets/theme/colors.scss';

.fasatRadioGroup {
  &.withError {
    input {
      border-color: $red_error;
      border-width: 2px;
    }
  }
  [data-testid^="radio-group"] {
    div {
      &:first-child {
        margin-top: 0;
      }
      input {
        margin-left: 0;
      }
    }
  }
}