@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.exportSubHeading {
  font-weight: normal;
  margin-top: -10px;
}
.modalHeading {
  font-family: $global-font-light;
  font-size: 30px;
  line-height: 1.33;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: -24px;
}
.buttonContainer {
  text-align: right;
  margin-top: 32px;
  padding-bottom: 40px;
  .left, .right{
    display: inline-block;
    max-width: min-content;
    word-break: break-all;
    }
  .right{
    padding-left: 20px;
  }  
  &.modalBtnContainer {
    button {
      margin-left: 20px;
    }
    .right {
      padding-left: 0;
    }
  }
  .tertiaryBtn {
    float: left;
    button {
      margin: 0;
    }
  }
}
div[data-testid="backdrop"] {
  z-index: 99996;
}
.modalWithoutHeading {
  .modalHeading {
    display: none;
  }
  .fasatMessageCard {
    padding-bottom: 20px;
  }
}
.compStructDateConflictModal {
  .formContent{
    width:480px;
    font-family: $global-font-light;
  }
  font-weight: 600;
}
.compStructDateErr{
  font-weight: 600;
}
.approveModal {
  font-family: $global-font-demibold;
  margin-top: -24px;
}
div[id^="modal-id"] {
  padding: 40px 40px 0 40px;
  max-height: 95vh;
  z-index: 99996;
  border: none;
  .fasatMessageCard {
    margin-bottom: 20px;
  }
}
/*Concatinating two labels*/
.concatLabels {
  > div {
    display: inline-block;
  }
}
div[id^="modal-id"] > div:first-child {
  display: inline;
}
button[id^="modal-closeButton"], button[data-testid="closeButton"] {  
  padding: 0;
  right: 12px;
  top: 12px;
}

.elementSeparator {
  padding-top: 12px;
  label {
    margin-bottom: 12px;
    font-size: 18px;
  }
  font-size: 18px;
}

.batchNumGrid {
  margin: 2px 51px 30px 60px;
  border : 1px solid lightgrey !important;
  ul{
    list-style: none;
    height: 280px !important;
    margin: 0px;
    overflow-y: auto !important;
    padding-left: 0px !important;
  }
  .batchNumHeader {
    padding: 12px 12px 2px 12px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  hr {
    border: 1px solid lightgrey;
  }
  ul li{
    border-bottom: 1px solid lightgrey !important;
    padding: 12px 12px 12px 12px !important;
  }
}

.form-row {
  padding-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
  }
  .form-element {
    display: inline-block;
    vertical-align: top;
    padding-right: 4.5%;
    &.lro {
      padding-right: 20px;
    }
    &.batchNum {
      margin-bottom: 40px !important;
    }
    &:last-child {
      padding-right: 0;
    }
    &.col-sm-12{
      width: 100%;
    }
    &.col-sm-6 {      
      width: 47.7%;
    }
    &.col-sm-8 {
      width: 64.7%;
    }
    &.col-sm-4 {      
      width: 30.3%;
    }
    &.commonFE {      
      width: 308px;
      padding-right: 20px;
    }
    &.col-sm-3 {      
      width: 21.4%;
    }
    &.col-sm-2 {      
      width: 308px;
    }
    &.w320 {
      width: 320px;
    }
    &.alterListPos .fasatDropdown ul {
      top: auto;
      bottom: 65%;
    }
    &.roleList .fasatDropdown ul {
      bottom: 82px;
      border-bottom: none;
    }
    &.roleList .fasatDropdown {
      position: absolute;
      width: 100%;
    }
    &.alterListPos.roleList .fasatDropdown div[display='block'] {
      position: initial;
      >div:nth-child(1) {  
        position: absolute;
        width: 100%;
        top: -16px;
        input {
          outline: none;
        }
      }
      >div:nth-child(2) {  
        top: auto;
        bottom: 63%;
        margin-bottom: 44px;
      }
    }
    &.alterListPos.roleList {
      .truncated.summary {
        top: 86px;
        position: absolute;
      }
    }
    &.fixHeight .fasatDropdown ul {
      height: 200px;
    }
    &.valignBottom {
      vertical-align: bottom;
    }
  }
}

.hrSeperator {
  margin: 16px 0 32px;
  border-top: 1px solid $light_grey;
}

.gevParentModal {
  [data-testid="CustomGridComponent"] {
    overflow-x: hidden;
        
    td.resizerCell {
      word-break: break-word;
    }
  }
  .buttonContainer {
    display: none;
  }
  .formContent {
    padding-bottom: 40px;
  }
}
.AUP-list {
  width: 100%;
  overflow-x: auto;
}

.customFilterListModal {
  .buttonContainer{
    display: none;
  }
  .footerBarWrapper {
    margin-bottom: 40px;
  }
  [data-testid="CustomGridComponent"] {
    min-height: 53vh;
    width: 89%;
    tr {
      td {
        .fasatExpandableParagraph {
          button {
            display: inline;
          }
        }
      }
    }
  }
  .dateFormat {
    display: inline-block;
    font-weight: 300;
  }
  .taGrid {
    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}

.shareTempModal {
  .form-row {
    .form-element {
      width: 80%;
      &.groupDD {
        width: 62%;
      }
      .multiTextArea {
        display: flex;
        margin-bottom: 8px;
        .textInputs {
          width: 78%;
        }
        div[id^="soAction"] {
          flex: 0;
          display: flex;
          button {
            margin-left: 8px;
            border: none;
            background: none;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.reportOutputModal {
  .form-row {
    .form-element {
      width: 80%;
      &.groupDD {
        width: 72%;
      }
    }
  }
}

.reportScheduleModal {
  .form-row {
    .form-element {
      width: 80%;
      &.statusgentime {
        display: flex;
      }
      &.repgentime {
        width: 100%;
        margin-left: 20px;
      }
      &.repfreq {
        width: 52%;
      }
      &.dynamicreport {
        display: flex;
      }
      &.dynamicfrequencylabel {
        margin-top: 34px;
      }
      &.heading1 {
        font-size: 20px;
        font-weight: 100;
      }
      &.heading2 {
        font-size: 20px;
        font-weight: 100;
      }
      &.groupDD {
        width: 68%;
      }
      .multiTextArea {
        display: flex;
        margin-bottom: 8px;
        .textInputs {
          width: 85%;
        }
        div[id^="soAction"] {
          flex: 0;
          display: flex;
          button {
            margin-left: 8px;
            border: none;
            background: none;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.maModalContent {
  .form-row {
    display: flex;
  }
  .form-row .form-element {
    padding-top: 12px;
    width: 240px;
    padding-right: 20px;
    &:not(:last-child){
      border-right: solid 1px $light_grey;
      margin-right: 20px;
    }
  }
  .fasatCheckbox {
    display: flex;
    margin-top: 0px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .labelContent {
      margin-top: 5px;
    }
  } 
  .sgrName{
    margin-bottom: 20px;
    font-family: $global-font-demibold;
    height: 24px;
  }
  .childCheckBox {
    padding-left : 38px

  }
}

.noLabelDatePicker {
  .fasatDatePicker {
    div[class^="styledComponents__LabelWrapper"] {
      display: none;
    }
  }
}

.addEditPDCalendar {
  [class^="styledComponents__Picker"] {
    right: 0;
  }
}

.addEditNotifModal {
  .columnFilterContainer {
    .rdl-list-box {
      width: 324px;
    }
    .rdl-actions {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.addEditRateDetailModal {
  .modalSubHeading {
    margin: 20px 0;
    font-size: 22px;
  }
}
.copySweepBanding{
 .sweepSearch{
  margin-top: 32px;
}
 .CopySweepBandingList{
  margin-top: 20px;
}
}

@media screen and (max-width: 599px) {
  .form-row {
    flex-direction: column;
    padding-bottom: 0;
    .form-element {
      width: 100% !important;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }
  .buttonContainer {
    flex-direction: column-reverse;
    .left, .right {
      width: 100%;
      max-width: initial;
      margin-bottom: 20px;
      padding-left: 0;
      button {
        width: 100%;
      }
    }
  } 
}

.clientAddressModal {
  .clientAddressView{
    margin-top: -24px;
    padding-bottom: 16px;
    label{
      margin-bottom: 20px;
      pre{
        font-family: $global-font-regular;
        font-weight: normal;
        margin: 0;
      }
    }
  }
}

.modalPREcontent {
  white-space: break-spaces;
  font-family: $global-font-demibold;
  margin: 0;
}

@media screen and (min-width: 1281px) {
  .customFilterListModal {
    [data-testid="CustomGridComponent"] {
      width: 91%;
    }
  }
}
.footer-text{
  margin-bottom: 40px;
}
.jobStatusCopyButton{
  align-items: end;
  color: grey !important;
  text-decoration: none !important;
}

.batchNumPopup > .buttonContainer {
  display: none !important;
}
.addEditInterfaceSetupFrom{
  .filePath{
    margin-top: 8px;
  }
}
.autoReportsDetails {
  .detailsContainer {
      margin: 32px 0;
      .rowContainer {
        display: flex;
        margin-bottom: 20px;
      }
      .columnContainer {
        width: 23.7%;
        margin-right: 32px;
        label {
          margin-bottom: 0;
          span {
            display: inline-block;
          }
        }
      }
      .scrollBox {
        height: 220px;
        overflow-y: auto;
        border: 1px solid #979797;
        padding: 12px;
        margin-top: 20px;
      }
    }
}

.personalizeModal {
  div[data-testid="FasatColumnOrderComponent"] {
    margin-top: 24px;
  }
}

@media screen and (max-width: 599px) {
  .form-row {
    flex-direction: column;
    padding-bottom: 0;
    .form-element {
      width: 100% !important;
      padding-right: 0;
      padding-bottom: 20px;
      .shareTempModal & {
        &.groupDD {
          width: 78% !important;
        }
      }
      .reportOutputModal & {
        &.groupDD {
          width: 78% !important;
        }
      }
      .reportScheduleModal & {
        &.groupDD {
          width: 78% !important;
        }
      }
    }
  }
  .buttonContainer {
    flex-direction: column-reverse;
    display: flex;
    .left, .right {
      width: 100%;
      max-width: initial;
      margin-bottom: 20px;
      padding-left: 0;
      button {
        width: 100%;
      }
    }
    &.modalBtnContainer {
      margin-top: 8px;
      button {
        margin: 0;
      }
    }
  } 
}

@media screen and (min-width: 1281px) {
  .customFilterListModal {
    [data-testid="CustomGridComponent"] {
      width: 91%;
    }
  }
}