@import '../../assets/theme/globalFonts.scss';
@import '../../assets/theme/colors.scss';
.fasatExpandablePanel {
  padding-bottom: 8px;
  [data-testid$="header-title"] {
    font-family: $global-font-demibold;
    margin-top: 0;
  }
  .ffBalanceProdTabLand & {
    margin-top: 32px;
    padding-bottom: 0;
  }
}

// .manualAdjustment {
//   .fasatExpandablePanel {
//     margin : 25px;
//     border : solid 1px $light_grey;
//     padding : 20px
//   }
// }