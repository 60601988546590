.fasatTextArea {
  &.withError {
    div[data-testid="input-wrapper"] {
      border-width: 2px;
    }
    div[id$='-errors'] {
      margin: 0;
    }
  }
  .optionalTextArea & {
    div[class^='styledComponents__LabelRow'] {
      display: flex;
      div[class^='styledComponents__MaxLength'] {
        flex: 1;
        text-align: right;
        margin-top: -32px;
      }
    }
  }
}