/**
* @Description File generated by awesome-react-template
* @FileName index.scss
* @Author  Arnab Sutar - sutaarn 
* @CreatedOn 08 January, 2021 14:21:15
* @IssueID - 459
*/

@import './app/common/assets/theme/colors.scss';
@import '@manulife/mux/core/typography/assets/fonts/fonts.css';
@import './app/common/assets/fonts/fonts';
@import './app/common/assets/theme/fonts.scss';
@import './app/common/assets/theme/globalFonts.scss';
@import './app/common/assets/theme/alignment.scss';
@import 'react-toastify/dist/ReactToastify.css';
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: $global-font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font300 {
  font-weight: 300;
}

ul[role=menu] {
  li {    
    ul[role=menu] {
      li:last-child {
        display: block;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.floatLeft{
  float: left;
}

.floatRight{
  float: right;
}
.noWrapLabel {
  label {
    white-space: nowrap;
  }
}

.absoluteHidden{
  position: absolute;
  height: 0;
  width: 0;
  top:-1000px;
}

.strikeThrough{
  label {
    text-decoration: line-through;
  }
}

.homeAnchor {
  color: $link_green;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $dark_2_light_grey;
}
::-webkit-scrollbar {
  height: 8px;
  width: 12px;
  background-color: $dark_2_light_grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $light_4_dark_navy;
}
.mainLayoutContainer {
  padding-bottom: 0 !important;
}
.manageGroupFieldset {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  legend{
    position: absolute;
    opacity: 0;
    font-size: 1px;
    right: 0;
  }
}
.rmLstRowPadding{
  padding-bottom: 0 !important;
}

.hideActionUpDown .actionUpDown {
  display: none;
}

.deskDisp {
  display: block !important;
  &.filterR {
    display: flex !important;
  }
}

.noDeskDisp {
  display: none !important;
}

div[data-testid="main-nav"] {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

@media screen and (max-width: 1279px) {
  .desktopBR {
    display: none;
  }
}

@media screen and (max-width: 763px) {
  .noDeskDisp {
    display: block !important;
  }
  .deskDisp {
    display: none !important;
    &.filterR {
      display: none !important;
    }
  }
}
div#notify-later-modal {
  overflow: initial !important;
  z-index: 99996;
}

.fasatPage.msActivity {
  padding: 0;
}

.detail-activity-grid{
  tr {
    td {
      padding: 8px;
      text-align: left !important;
      border-bottom: 2px solid $light_grey;
    }
    th {
      padding: 8px;
      border-top: 2px solid $light_grey;
      border-bottom: 2px solid $light_grey;
      padding-right: 15px;
    }
    border-bottom: 2px solid $light_grey;
  }

  .row-header {
    padding-top: 10px;
    border-bottom: 2px solid $light_grey;
  }

  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .grid {
    overflow-x: auto;
  }

  .invisible {
    min-width: 250px;
  }

  .detailedActivityExportSection {
    text-align: right;
    margin-bottom: 12px;
  }

  .fasatHTMLgrid-new {
    caption {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      -webkit-clip: rect(1px,1px,1px,1px);
      clip: rect(1px,1px,1px,1px);
    }

    tr td.withNoRow {
        position: inherit !important;
        text-align: left !important;
    }
  }
}
