@import '../../assets/theme/globalFonts.scss';
.fasatExpandableParagraph {
  button {
    width: auto;
    display: block;
  }
  div {
    display: inline;
  }
  .errTitle {    
    font-family: $global-font-regular;
  }
  pre {
    font-family: $global-font-regular;
    margin-top: 0;
    text-wrap: wrap;
  }
}