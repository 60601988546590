@import '../../../assets/theme/colors.scss';
@import '../../../assets/theme/globalFonts.scss';
.so-filterTable {
    display: flex;
    flex-direction: column;
  
    .so-filterRow {
      display: flex;
      margin-bottom: 12px;
      &:first-child {
        margin-bottom: 8px;
      }
      &:last-child {
       margin-bottom: 0;
      }
  
      .so-filterCell,
      .so-actionCell,
      .so-blankCell {
        flex-grow: 1;
        margin: 0 20px 0 0;
        flex-basis: 20%;
        font-family: $global-font-demibold;
      }
      .so-filterCell {
        .fasatDropdown > div > label {
          display: none;
        }
        div {
          line-height: 0.9;
          div[id$='-value'] {
            line-height: 1.3;
          }
        }
        width: 18%;
      }
      .so-sortOrderCell {
        margin-right: 8px;
      }
      .so-blankCell {
          flex-basis: 50%;
      }
      .so-actionCell {
        display: flex;
        flex-basis: 10%;
        button {
          border: none;
          background: transparent;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 20px;
          margin: 12px 4px 0;
          height: 20px;
          padding: 0;
          width: 20px;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            color: $m_green;
          }
          &:active {
            color: $dark_2_green;
          }
        }
      }
    }
  }
  .dupSelectionMsg {
    font-family: $global-font-demibold;
  }