@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.detailsContainer {
  &.aeTdDetailsContainer {
    margin-top: 0;
    // .optionalLabel {
    //   margin-bottom: 0;
    // }
    label {
      margin-bottom: 8px;
    }
    .fasatCheckbox {
      display: flex;
      .labelContent {
        margin-top: 6px;
      }
    }
    .rowContainer {
      .columnContainer {
        &:last-child {
          .fasatCheckbox {
            .labelContent {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  &.addEditCloneFinArrForm {
    margin-top: 32px;
    h2 {
      margin-top: 32px;
      margin-bottom: 20px;
    }
    label {
      margin-bottom: 8px;
    }
  }
  &.sysOptionDetails, &.addEditClientAddForm, &.addEditClientLicenceForm, &.addEditCliStrutForm, &.addEditDeductions, &.addEditCloneFinArrForm {
    .cliDetailsButtonSection {
      button {
        margin-right: 20px;
      }
    }
  }
  .formControl{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    &.datePickerControl{
      label{
        margin-bottom: 8px;
      }
      button {
        bottom: 2px;
      }
    }
    &.editFormControl {
      label {
        margin-bottom: 8px;
      }
    }
    &.checkBoxControl {
      &.checked {
        label {
          font-weight: normal;
        }
      }
      span[class^='styledComponents__CheckboxWrapper']{
        margin: 0 10px 0 0;
      }
    }
    &.earlySwtchTime {
      width: 332px;
      .form-element {
        padding-right: 0;
        margin-right: 0;
      }
      .colon {
        padding: 0 12px;
        vertical-align: -webkit-baseline-middle;
        display: inline;
        font-weight: 600;
      }
    }
  }
}

.fasatTab {
  .sysOptTabsContainer, .gridBarContainer {
    .fasatGridBar {
      padding: 32px 0 20px;
      padding-top: 0;
      margin-top: 32px;
      .actionSection {
        button {
          margin-top: 0;
        }
      }
    }
  }
}

#addEditFFTask {
  .addEditTaskTab {
    padding-top: 32px;
    h3 {
      font-size: 22px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      margin: 12px 0 20px;
      color: $light_1_dark_navy;
    }
    .tabFooterBtn {
      .left {
        padding-left: 0;
      }
    }
    .noDisplayBR {
      display: none;
    }
    .displayBR {
      display: block;
    }
  }
  .taskCol {
    display: inline-block;
    width: 308px;
    margin-bottom: 20px;
    margin-right: 20px;
    vertical-align: top;
    &.taskCol1, &.taskCol4, &.taskCol7, &.taskCol10 {
      display: block;
    }
    &.lastRowTaskCol {
      margin-bottom: 0;
    }
  }
}