@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';
.dailyProcess {
  color: #DB1F00;
}
.optionalLabel, .additionalLabel {
  label {
    display: inline;
  }
  margin-bottom: 8px;
  .optionalText, .additionalText{
    font-weight: 300;
    color: $light_3_dark_navy;
    display: inline;
  }
}

.labelContent {
  word-break: break-word;
  label {
    font-weight: 400;
    .clientId &{
      margin-bottom: 24px;
    }
  }
}

span.dateFormatSpanPD {
  display: inline-block;
  font-weight: 300;
}

.hide-label {
  content-visibility: hidden;
}