@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';

.fasatProgressBar {
  span[data-testid="arrow"], span[data-testid="side-arrow"], span[data-testid="wrapper"] {
    left: 30px;
  }
}

.stepperContentArea {
  padding: 40px 0 12px 40px;
  border: 1px solid $light_grey;
  background-color: $white;
  .formElement {
    width: 308px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: inline-block;
    vertical-align: text-top;
    // .optionalLabel {
    //   margin-bottom: 0;
    // }
    &.sideBySide {
      .fasatTextInput {
        width: 190px;
        display: inline-block;
      }
      .formActionBtn {
        display: inline-block;
        margin-left: 18px;
      }
    }
  }
  .formElementBCard {
    width: 400px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: inline-block;
    vertical-align: text-top;
  }
  .chkBoxGrpFormElement {
    padding-top: 12px;
    .optionalLabel{
      margin-bottom: 0;
      // label {
      //   margin-bottom: 0;
      // }
    }
    .fasatCheckboxGroup {
      fieldset {
        div[direction='row'] {
          div[direction='row'] {
            width: 308px;
            padding: 0 0 20px 0;
            margin: 0;
          }
        }
      }
      div[direction]:first-of-type {
        padding-left: 0;
      }
    }
  }
  .controllerSection {
    padding-top: 12px;
    &.addressStepFF {
      padding-top: 0;
    }
    .controllerHeader {
      font-family: $global-font-light;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: $light_1_dark_navy;
      padding-bottom: 20px;
      margin: 0;
    }
    .controllerInfoHeader {
      padding: 12px 0;
      font-family: $global-font-regular;
      font-size: 22px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $light_1_dark_navy;
    }
  }
  &.ff {
    padding: 40px 0 12px 0;
    border: 0;
  }
}

.buttonArea {
  padding: 32px 0 40px;
  display: flex;
  .tertiaryButtonArea {
    flex: 1;
    text-align: left;
    button {
      margin-left: 0;
    }
  }
  .prSecButtonArea {
    flex: 1;
    text-align: right;
    button {
      margin-left: 20px;
    }
  }
}