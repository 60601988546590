@import '../../assets/theme/globalFonts.scss';

.filterSettingsPanel {
  position: relative;
  .chipSection {
    margin-left: 20px;
    .chipContainer {
      margin-right: 12px;
      margin-top: 8px;
    }
  }
  p, h2 {
    font-family: $global-font-regular;
    &.panelHeaderText {
      font-family: $global-font-light;
      font-size: 30px;
      margin: 0;
      margin-top: 5px;
      line-height: normal;
      max-width: 32%;
      font-weight: normal;
    }
    &.sysDefaultFilterHeader  {
      max-width: none; 
    }
  }
  .filterSettingsPanel_level2 {
    padding: 16px 0 20px;
    &>div {
      border-top: 1px solid #ededed;
      padding: 20px 0;
      &:last-child {
        border-bottom: 1px solid #ededed;
      }
    }
  }  
  .panelFooterActionContainer {
    display: flex;
    text-align: right;    
    justify-content: flex-end;
    padding-bottom: 20px;
    button {
      margin-left: 20px;
    }
    .fasatDropdown {
      margin-right: 20px;
      button {
        width:100px;
        overflow:hidden;
        div {
          height: auto;
          line-height: 24px;
        }
      }
    }
  }
  .panelHeaderSection {
    display: flex;
    padding-top: 20px;
    .panelActionArea {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      button[id^="actionbtn"] {
        margin-top: 14px;
        @-moz-document url-prefix() {
          margin-top: 17px;
        }
      }
      .loadFilter {
        margin-left: 24px;
        margin-top: 4px;
        &>div {
          display: inline-flex;
          vertical-align: middle;
          label {
            font-family: $global-font-light;
            font-weight: 300;
            margin-right: 12px;
            margin-top: 5px;
          }
        }
        button, select {
          width: 180px;
        }
        .fasatDropdown > div > label {
          display: none;
        }
      }
    }
  }
  .filterSettingsPanelText {
    margin-top: 20px;
  }
  .filterSubAccordionPanelText {
    margin: 8px 0 20px;
  }
  span[data-testid$="-header-title"] {
    font-family: $global-font-demibold;
    margin-top: 0;
  }
  div[id$="-body"] {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

@media screen and (max-width: 763px) {
  .filterSettingsPanel {
    .panelHeaderSection {
      display: block;
      .panelActionArea {
        display: block;
        text-align: right;
        button[id^=actionbtn] {
          margin-bottom: 8px;
        }
      }
    }
    .fasatButtonSelector {
      div[class^="styledComponents__Root"] {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .filterSettingsPanel {
    .panelFooterActionContainer {
      display: flex;
      flex-direction: column-reverse;
      button {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}