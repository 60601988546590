@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';
.fasatPageTitle {
  text-align: left;
  font-family: $global-font;
  font-size: 44px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $light_1_dark_navy;
  margin-bottom: 20px;
  margin-top: 0;
  span {
    font-family: $global-font-italic;
  }
}
.titleSection {
  display: flex;
  .fasatPageTitle {
    width: 70%;
    &.ptNoAction {
      width: 100%;
    }
  }
  .ptAction {
    flex: 1;
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: right;
    button {
      margin-left: 20px;
    }
  }
}