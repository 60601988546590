@import '../../assets/theme/globalFonts.scss';

.Toastify__toast-container {
    width: auto;
    min-width: none;
    background: none;
  }
  .fasatQuickActionNotification {
    box-shadow: none;
    background: none;
    .messageContainer {
      display: flex;
      .iconContainer {
        margin-top: 0px;
      }
      .message {
        margin: 0 0 0 0px;
        font-family: $global-font;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff;
        background-color: #34384b;
      }
    }
  }
  