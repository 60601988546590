@import '../../assets/theme/colors.scss';
@import '../../assets/theme/globalFonts.scss';


@media screen and (max-width: 1023px) {
  .fasatPage {
    &.extFasatPage {
      .fasatQuickLinks {
        width: 100%;
      }
    }
  }
}

.fasatPage {
  &.landing {
    padding-bottom: 60px;
    min-height: 100vh;
  }
  .fasatQuickLinks {
    border: 1px solid #ececec;
    padding: 16px 0 12px;
    width: 788px;
    margin-bottom: 209px;
    .quickLinksHeader {
      display: flex;
      padding: 0 32px 8px;
      border-bottom: 1px solid #ededed;
      .headerLabel {
        flex: auto;
        h2 {
          font-family: $global-font;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          margin: 0;
        }
      }
      .personalizeButton {
        flex: 1;
        text-align: right;
      }
      .actionsArea {
        text-align: right;
      }
      button {
        margin-left: 20px;
        margin-bottom: 8px;
        &:first-child {
          margin-left: 0;
        }
        &:disabled {
          pointer-events: none;
          span[class^="styledComponents__IconWrapper"] {
            color: $light_3_dark_navy;
          }
        }
        span[class^="styledComponents__ButtonLabel"] {
          padding-top: 8px;
        }
      }

      
    }
    .quickLinksSection {
      padding: 32px 0 0;
      .quickLinkRow {
        .quickLinkCol {
          display: inline-block;
          width: 220px;
          margin-left: 32px;
          margin-bottom: 20px;
          vertical-align: top;
        }
        .noLinkSection {
          padding: 56px 0;
          text-align: center;
          font-size: 22px;
          line-height: 1.45;
          color: $light_1_dark_navy;
        }
      }
    }
  }
}

.landingWithBE {
  .fasatQuickLinks {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 599px) {
  .fasatPage {
    .fasatQuickLinks {
      .quickLinksHeader {
        .actionsArea {
          flex: 50%;
          text-align: right;
        }
      }
    }
  }
}
